import React from "react";
import { View, StyleSheet } from "react-native";

import Caption from "./caption";
import { m } from "../theme";

export default function Field({ children, title, style }) {
  return (
    <View style={[m.b3, style]}>
      <Caption style={$.title}>{title.toUpperCase()}</Caption>
      {children}
    </View>
  );
}

const $ = StyleSheet.create({
  title: {
    ...m.b2,
    fontWeight: "bold",
  },
});
