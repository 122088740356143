import React from "react";
import { Picker as RNPicker, StyleSheet } from "react-native";

import { colors, p, roundness } from "../theme";

export default function Picker({
  error = false,
  items,
  onChange,
  placeholder,
  style,
  value,
  ...rest
}) {
  return (
    <RNPicker
      selectedValue={value}
      onValueChange={onChange}
      style={[$.container, error && $.error, style]}
      {...rest}
    >
      {Boolean(placeholder) && <RNPicker.Item label={placeholder} value="" />}
      {items.map((item) => {
        const label = item.label || item.name || item;
        const value = item.value || item.id || item;

        return <RNPicker.Item {...{ key: `item-${value}`, label, value }} />;
      })}
    </RNPicker>
  );
}

const $ = StyleSheet.create({
  container: {
    ...p[3],
    borderWidth: 2,
    borderColor: colors.divider,
    borderRadius: roundness,
  },
  error: {
    borderColor: colors.error.divider,
  },
});
