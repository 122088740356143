import React, { PureComponent } from "react";
import { TextInput as RNTextInput, StyleSheet } from "react-native";

import { colors, roundness, p } from "../theme";

export default class TextInput extends PureComponent {
  handleSubmit = (ev) => {
    const { onSubmit, onSubmitEditing } = this.props;

    if (onSubmitEditing) {
      onSubmitEditing(ev);
    } else if (onSubmit) {
      onSubmit(ev.nativeEvent.text);
    }
  };

  render() {
    const { error, style, small, ...rest } = this.props;

    return (
      <RNTextInput
        placeholderTextColor={colors.muted}
        {...rest}
        style={[$.base, small && $.small, error && $.error, style]}
        onSubmitEditing={this.handleSubmit}
      />
    );
  }
}

const $ = StyleSheet.create({
  base: {
    ...p[3],
    fontSize: 16,
    borderRadius: roundness,
    borderWidth: 2,
    borderColor: colors.divider,
    color: "black",
  },
  small: {
    ...p[2],
    // fontSize: 14,
    borderRadius: roundness / 2,
    borderWidth: 1,
  },
  error: {
    borderColor: colors.error.surface,
  },
});
