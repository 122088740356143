import React from "react";
import { Image, StyleSheet } from "react-native";

import { colors } from "../theme";

import sources from "../icons";

export default function Icon({
  small,
  large,
  medium,
  name,
  color = "black",
  style,
}) {
  const source = sources[name];

  if (!source) {
    throw new Error(`Icon "${name}" does not exist`);
  }

  const tintColor = colors[color] || color;

  return (
    <Image
      {...{
        source,
        style: [
          $.container,
          small && $.small,
          medium && $.medium,
          large && $.large,
          { tintColor },
          style,
        ],
      }}
    />
  );
}

const $ = StyleSheet.create({
  small: {
    width: 14,
    height: 14,
  },
  container: {
    width: 24,
    height: 24,
  },
  medium: {
    width: 36,
    height: 36,
  },
  large: {
    width: 48,
    height: 48,
  },
});
