import React from "react";

import { Icon, Row } from "../lib";

export default function Stars({ value, large = false }) {
  const main = Math.floor(value);
  const remainder = Math.round(value - main);

  const stars = [
    <Icon key="star-1" name="star_outline" color="yellow" small={!large} />,
    <Icon key="star-2" name="star_outline" color="yellow" small={!large} />,
    <Icon key="star-3" name="star_outline" color="yellow" small={!large} />,
    <Icon key="star-4" name="star_outline" color="yellow" small={!large} />,
    <Icon key="star-5" name="star_outline" color="yellow" small={!large} />,
  ];

  for (let i = 0; i < main; i++) {
    stars.splice(
      i,
      1,
      <Icon key={`star-${i + 1}`} name="star" color="yellow" small={!large} />
    );
  }

  if (remainder) {
    stars.splice(
      4,
      1,
      <Icon key="remainder" name="star_half" color="yellow" small={!large} />
    );
  }

  return <Row>{stars.map((s) => s)}</Row>;
}
