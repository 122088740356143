import { StyleSheet } from "react-native";

import Text from "./text";

export default function Title({ style, ...rest }) {
  return <Text style={[$.text, style]} {...rest} />;
}

const $ = StyleSheet.create({
  text: {
    fontSize: 24,
  },
});
