import React from "react";

import Message from "./message";
import { Field, Picker } from "../lib";
import { m } from "../theme";

export default function RegionsField({
  cities,
  disabled,
  onChange,
  items,
  value,
}) {
  return (
    <Field title="Choose package">
      <Picker
        {...{
          enabled: !disabled,
          items,
          onChange,
          value,
        }}
      />
      {cities.length > 0 && (
        <Message style={m.t2}>
          You will have access to our premium features in the following cities:{" "}
          {cities}
        </Message>
      )}
    </Field>
  );
}
