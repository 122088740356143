import { denormalize } from "normalizr";
import { createSelector as $, createCachedSelector as $$ } from "@rnna/db";

import * as schemas from "./schemas";

// US & CA states
export const getStates = $("ui.states", "db.tables", (ids, tables) => {
  return denormalize(ids, [schemas.state], tables);
});

// Regions
export const getRegion = $("ui.regionId", "db.tables", (id, tables) => {
  return denormalize(id, schemas.region, tables);
});

// User
export const getUser = $("session.userId", "db.tables", (id, tables) => {
  return denormalize(id, schemas.user, tables);
});

// session
export const isAuthenticated = $("session.accessToken", (token) => {
  return Boolean(token);
});

export const isPremium = $(
  isAuthenticated,
  "session.premium",
  (authenticated, premium) => {
    return authenticated && premium;
  }
);
export const getAccessToken = $("session.accessToken");
export const getRefreshToken = $("session.refreshToken");
export const getRedirect = $("session.redirect");

// UI
export const isLoading = $("ui.loading");
export const getCityId = $("ui.cityId");
export const getGenderId = $("ui.genderId");
export const getVideosPage = $("ui.page");
export const getError = $("ui.error");
export const getErrors = $("ui.errors");
export const getErrorFields = $("ui.errorFields");

// Videos
export const hasVideos = $("db.queries", (queries) => {
  return Boolean(queries["videos?page=1"]);
});

export const getAllVideos = $(
  isPremium,
  "db.queries",
  "db.pages",
  "db.tables",
  (premium, queries, pages, tables) => {
    if (premium) {
      const ids = (pages["videos?"] || []).reduce(
        (acc, page) => acc.concat(page),
        []
      );

      return denormalize(ids, [schemas.video], tables);
    }

    // Tease
    return denormalize(queries["videos?"], [schemas.video], tables);
  }
);

export const isTeasing = $(getAllVideos, (videos) => {
  return (videos || []).length === 3;
});

export const findPurchaseByRegion = $$(
  getUser,
  (state, id) => id,
  (user, id) => {
    if (!user) {
      return null;
    }

    return user.purchases.find(
      (purchase) => purchase.active && purchase.plan.region_id === id
    );
  }
)((state, id) => id);
