import { schema } from "normalizr";

export const city = new schema.Entity("cities");

export const country = new schema.Entity("countries");

export const review = new schema.Entity("reviews");

export const model = new schema.Entity("models", {
  reviews: [review],
});

export const plan = new schema.Entity("plans");

export const purchase = new schema.Entity("purchases", {
  plan,
});

export const state = new schema.Entity("states");

export const user = new schema.Entity("users", {
  purchases: [purchase],
});

export const video = new schema.Entity("videos", {
  model,
});

export const region = new schema.Entity("regions");
