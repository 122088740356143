import React from "react";
import { StyleSheet } from "react-native";

import Message from "./message";
import { Field, Input, Picker, Row } from "../lib";
import { m } from "../theme";

export default function AccountField({
  errors,
  onLogin,
  fullName,
  onFullName,
  email,
  onEmail,
  emailConfirmation,
  onEmailConfirmation,
  phoneCountries,
  phoneCountry,
  onPhoneCountry,
  phoneNumber,
  onPhoneNumber,
}) {
  return (
    <Field title="Account">
      <Message action="Log in" onAction={onLogin} style={m.b2}>
        Already a customer ?
      </Message>

      <Input
        value={fullName}
        onChange={onFullName}
        placeholder="Your name"
        style={m.b2}
        error={Boolean(errors.full_name)}
      />

      <Input
        value={email}
        onChange={onEmail}
        placeholder="Email"
        style={m.b2}
        error={Boolean(errors.email)}
      />

      <Input
        value={emailConfirmation}
        onChange={onEmailConfirmation}
        placeholder="Confirm email"
        style={m.b2}
        error={Boolean(errors.email_confirmation)}
      />

      <Row.LR>
        <Picker
          value={phoneCountry}
          onChange={onPhoneCountry}
          enabled={phoneCountries.length > 0}
          items={phoneCountries}
          placeholder="+XX"
          style={$.phoneCountry}
          error={Boolean(errors.phone_country || errors.phone_number)}
        />

        <Input
          keyboardType="number-pad"
          value={phoneNumber}
          onChange={onPhoneNumber}
          placeholder="Phone number"
          style={$.phoneNumber}
          error={Boolean(errors.phone_country || errors.phone_number)}
        />
      </Row.LR>
    </Field>
  );
}

const $ = StyleSheet.create({
  phoneCountry: {
    ...m.r2,
    width: 85,
  },
  phoneNumber: {
    flex: 1,
  },
});
