import React from "react";
import { FlatList, StyleSheet } from "react-native";
import format from "date-fns/format";

import { Message, Screen } from "../components";
import { Action, Caption, Content, Loader, Row, Text, Title } from "../lib";
import { colors, m, p } from "../theme";

export default class Account extends Screen {
  static displayName = "Account";

  renderPurchase = ({ item: { amount, created_at: createdAt, plan } }) => {
    return (
      <Row.LR style={$.purchase}>
        <Caption>{format(new Date(createdAt * 1000), "d MMM y")}</Caption>
        <Row.R>
          <Text>{plan.name}</Text>
          <Caption style={{ textAlign: "right", minWidth: 75 }}>
            {amount.toFixed(2)} {plan.currency}
          </Caption>
        </Row.R>
      </Row.LR>
    );
  };

  renderContent() {
    const { user } = this.props;

    if (!user) {
      return <Loader />;
    }

    const header = (
      <>
        <Title style={m.b3}>Hello {user.name}.</Title>
        {!user.verified && (
          <Message>
            You haven't verified your email address. Please check your inbox.
          </Message>
        )}
      </>
    );

    const footer = (
      <Action
        alert
        title="Buy Premium"
        description="You don't have an active plan"
        onPress={this.dispatcher("showPurchase")}
        style={p.v4}
      />
    );

    return (
      <Content>
        <FlatList
          data={user.purchases}
          renderItem={this.renderPurchase}
          keyExtractor={({ id }) => `purchase-${id}`}
          ListHeaderComponent={header}
          ListFooterComponent={!user.plans.length && footer}
        />
      </Content>
    );
  }
}

const $ = StyleSheet.create({
  purchase: {
    ...p[3],
    borderBottomWidth: 1,
    borderBottomColor: colors.divider,
  },
});
