import storage from "redux-persist/lib/storage";

const config = {
  middlewares: [],
  persist: {
    key: "root",
    storage,
    whitelist: ["session"],
  },
};

export default config;
