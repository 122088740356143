import createStoreAsync from "rnna/store";

import epics from "./events";
import reducers from "./state";
import container from "./services";
import storeConfig from "./config/store";

const storeAsync = createStoreAsync(
  { ...storeConfig, epics, reducers },
  container
);

const app = {
  boot() {
    return storeAsync;
  },
};

export default app;
