import { StyleSheet, Text as RNText } from "react-native";

export default function Text({ style, ...rest }) {
  return <RNText style={[$.text, style]} {...rest} />;
}

const $ = StyleSheet.create({
  text: {
    fontSize: 16,
    // fontFamily: "Menlo, Consolas, Monaco, monospace",
  },
});
