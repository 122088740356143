import { Dimensions, StyleSheet, View } from "react-native";

import { p } from "../theme";

export default function Content({ style, ...rest }) {
  return (
    <View style={$.container}>
      <View style={[$.content, style]} {...rest} />
    </View>
  );
}

Content.Form = function Form({ style, ...rest }) {
  return <Content style={[$.form, style]} {...rest} />;
};

Content.List = function List({ style, ...rest }) {
  return <View style={[$.list, style]} {...rest} />;
};

const device = Dimensions.get("window");
const maxWidth = Math.min(device.width, 414);

const $ = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  content: {
    ...p[3],
    flex: 1,
    width: "100%",
    maxWidth,
  },
  form: {
    justifyContent: "flex-end",
  },
  list: {
    position: "absolute",
    top: 40, // topBar
    right: 0,
    bottom: 0,
    left: 0,
  },
});
