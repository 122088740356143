import React from "react";
import _times from "lodash.times";

import { Field, Input, Picker, Row } from "../lib";
import { device, m } from "../theme";

const YEARS = _times(10, (i) => String(2021 + i));

const MONTHS = new Array(12).fill().map((v, i) => {
  const value = String(i + 1).padStart(2, "0");

  return {
    value,
    label: `${value} - ${new Date(`2020-${value}-01`).toLocaleString(
      device.locale,
      {
        month: "long",
      }
    )}`,
  };
});

export default function CreditCardField({
  errors,
  cardName,
  onCardName,
  cardNumber,
  onCardNumber,
  expiryMonth,
  onExpiryMonth,
  expiryYear,
  onExpiryYear,
  cvv,
  onCvv,
}) {
  return (
    <Field title="Credit Card">
      <Input
        value={cardName}
        onChange={onCardName}
        placeholder="Full name"
        style={m.b2}
        error={Boolean(errors.card_name)}
      />

      <Input
        value={cardNumber}
        onChange={onCardNumber}
        placeholder="4111 1111 1111 1111"
        keyboardType="number-pad"
        style={m.b2}
        error={Boolean(errors.card_number)}
      />

      <Row.LR style={m.b2}>
        <Picker
          items={MONTHS}
          onChange={onExpiryMonth}
          value={expiryMonth}
          error={Boolean(errors.expiry_month)}
          style={[m.r2, $.x]}
        />

        <Picker
          items={YEARS}
          onChange={onExpiryYear}
          value={expiryYear}
          error={Boolean(errors.expiry_year)}
          style={$.x}
        />
      </Row.LR>

      <Input
        value={cvv}
        onChange={onCvv}
        placeholder="CVV"
        keyboardType="number-pad"
        error={Boolean(errors.cvv)}
      />
    </Field>
  );
}

const $ = {
  x: {
    flex: 1,
  },
};
