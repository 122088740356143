import React from "react";
import { Image, StyleSheet } from "react-native";

import { Row, Text } from "../lib";
import { colors, m } from "../theme";

export default function Avatar({ uri, name, ...rest }) {
  const image = <Image style={$.image} source={{ uri }} />;

  if (!name) {
    return image;
  }

  return (
    <Row.L {...rest}>
      {image}
      {Boolean(name) && <Text style={m.l2}>{name}</Text>}
    </Row.L>
  );
}

const $ = StyleSheet.create({
  image: {
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: colors.accent,
  },
});
