import React from "react";
import { StyleSheet } from "react-native";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { Caption, Col, Row, Text } from "../lib";
import { m, p } from "../theme";
import Stars from "./stars";

export default function ReviewItem({ comments, createdAt, score, ...rest }) {
  const dateStr = formatDistanceToNow(new Date(createdAt), {
    addSuffix: true,
  });

  return (
    <Col {...rest}>
      <Row.LR style={m.b2}>
        <Caption>{dateStr}</Caption>
        <Stars value={score} />
      </Row.LR>
      <Text style={$.quote}>{comments}</Text>
    </Col>
  );
}

const $ = StyleSheet.create({
  quote: {
    ...p.l2,
    fontStyle: "italic",
  },
});
