const sources = {
  account_circle: require("./account_circle.png").default,
  close: require("./close.png").default,
  menu: require("./menu.png").default,
  play_arrow: require("./play_arrow.png").default,
  radio_button_checked: require("./radio_button_checked.png").default,
  radio_button_unchecked: require("./radio_button_unchecked.png").default,
  star: require("./star.png").default,
  star_half: require("./star_half.png").default,
  star_outline: require("./star_outline.png").default,
};

export default sources;
