import React from "react";

import { Caption, Field, Radio, Row, Text } from "../lib";

export default function PlansField({ items, onChange, value }) {
  return (
    <Field title="Choose plan">
      {items.map(({ id, amount, currency, name }) => (
        <Radio
          {...{
            key: id,
            checked: value === id,
            label: (
              <Row.LR.X>
                <Text>{name}</Text>
                <Caption>
                  {amount} {currency}
                </Caption>
              </Row.LR.X>
            ),
            onChange,
            value: id,
          }}
        />
      ))}
    </Field>
  );
}
