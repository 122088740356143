import Account from "../views/screens/account";
import Login from "../views/screens/login";
import Purchase from "../views/screens/purchase";
import Video from "../views/screens/video";
import Videos from "../views/screens/videos";

const navigation = {
  Account,
  Login,
  Purchase,
  Video,
  Videos,
};

export default navigation;
