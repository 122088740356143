import React from "react";
import { createMask } from "imask";

import {
  Message,
  AccountField,
  AddressField,
  CreditCardField,
  PlansField,
  RegionsField,
  Screen,
} from "../components";
import { Button, Content } from "../lib";
import { m } from "../theme";

const masks = {
  cardNumber: createMask({ mask: "0000 0000 0000 0000" }),
  cvv: createMask({ mask: "000" }),
  expiry: createMask({ mask: "00/00" }),
};

const COUNTRY_US = 840;
const COUNTRY_CA = 124;

export default class Purchase extends Screen {
  static displayName = "Purchase";

  state = {
    // User
    fullName: "",
    email: "",
    emailConfirmation: "",
    phoneNumber: "",
    phoneCountry: "",

    // Address
    addressLine: "Exchange Tower",
    city: "Bangkok",
    countryId: "764",
    postCode: "10110",
    stateId: null,

    // CC
    cardName: "JOHN DOE",
    cardNumber: "4111111111111111",
    cvv: "123",
    expiryMonth: "01",
    expiryYear: "2024",

    // Plan
    planId: null,
    regionId: 2,
  };

  handleSubmit = () => {
    const {
      // User
      fullName,
      email,
      emailConfirmation,
      phoneNumber,
      phoneCountry,

      // Address
      addressLine,
      city,
      countryId,
      postCode,
      stateId,

      // CC
      cardName,
      cardNumber,
      cvv,
      expiryMonth,
      expiryYear,

      // Plan
      planId,
      regionId,
    } = this.state;

    const { plans, user } = this.props;

    const data = {
      // Address
      address_line: addressLine,
      city,
      country_id: countryId,
      post_code: postCode,

      // CC
      card_name: cardName,
      card_number: cardNumber,
      cvv,
      expiry_month: parseInt(expiryMonth, 10),
      expiry_year: parseInt(expiryYear, 10),

      // Plan
      plan_id: planId || plans[0].id,
      region_id: regionId,

      // Browser info
      screen_width: window.screen.availWidth,
      screen_height: window.screen.availHeight,
      browser_language: window.navigator.language,
      color_depth: window.screen.colorDepth,
      time_zone: new Date().getTimezoneOffset(),
    };

    if (stateId) {
      data["state_id"] = stateId;
    }

    if (user) {
      data["user_id"] = user.id;
    } else {
      Object.assign(data, {
        full_name: fullName,
        email,
        email_confirmation: emailConfirmation,
        phone_number: phoneNumber,
        phone_country: phoneCountry,
      });
    }

    this.dispatch("purchase", data);
  };

  handleCardNumber = (value) => {
    const val = value.replace(/[\s]/g, "");

    this.setState({
      cardNumber: masks.cardNumber.resolve(val),
    });
  };

  handleCountryId = (value) => {
    const id = parseInt(value, 10);

    const newState = { countryId: id };

    if (id === COUNTRY_US || id === COUNTRY_CA) {
      this.dispatch("showStates", id);
    } else {
      newState.stateId = null;

      this.dispatch("hideStates");
    }

    this.setState(newState);
  };

  handleCvv = (val) => {
    this.setState({
      cvv: masks.cvv.resolve(val),
    });
  };

  handleCardName = (value) => {
    this.setState({
      cardName: value.toUpperCase(),
    });
  };

  handleChangeRegion = (id) => {
    const regionId = parseInt(id, 10);

    this.setState({ regionId });

    this.dispatch("getPlansByRegion", regionId);
  };

  handleLogin = () => {
    this.dispatch("showLogin");
  };

  renderContent() {
    const {
      // User
      fullName,
      email,
      emailConfirmation,
      phoneNumber,
      phoneCountry,

      // Address
      addressLine,
      city,
      countryId,
      postCode,
      stateId,

      // CC
      cardName,
      cardNumber,
      cvv,
      expiryMonth,
      expiryYear,

      // Plan
      planId,
      regionId,
    } = this.state;

    const {
      cities,
      countries,
      errorFields,
      loading,
      plans,
      purchase,
      region,
      regions,
      states,
      user,
    } = this.props;

    return (
      <Content.Form>
        <RegionsField
          cities={purchase ? [] : cities.map(({ name }) => name).join(", ")}
          disabled={!plans.length || !regions.length}
          items={regions}
          onChange={this.handleChangeRegion}
          value={regionId}
        />
        {Boolean(purchase) && (
          <Message error style={m.b3}>
            You already have an active plan for {region.name}. Please select
            another package.
          </Message>
        )}
        {!purchase && plans.length > 0 && (
          <PlansField
            items={plans}
            onChange={this.setter("planId")}
            value={planId || plans[0].id}
          />
        )}

        {!user && (
          <AccountField
            {...{
              errors: errorFields,
              onLogin: this.handleLogin,
              fullName,
              onFullName: this.setter("fullName"),
              email,
              onEmail: this.setter("email"),
              emailConfirmation,
              onEmailConfirmation: this.setter("emailConfirmation"),
              phoneCountries: countries.map(
                ({ code, name, calling_code: callingCode }) => ({
                  label: `${name} +${callingCode}`,
                  value: code,
                })
              ),
              phoneCountry,
              onPhoneCountry: this.setter("phoneCountry"),
              phoneNumber,
              onPhoneNumber: this.setter("phoneNumber"),
            }}
          />
        )}

        <AddressField
          {...{
            errors: errorFields,
            addressLine,
            onAddressLine: this.setter("addressLine"),
            postCode,
            onPostCode: this.setter("postCode"),
            city,
            onCity: this.setter("city"),
            countries,
            countryId,
            onCountryId: this.handleCountryId,
            states,
            stateId,
            onStateId: this.setter("stateId"),
          }}
        />

        <CreditCardField
          {...{
            errors: errorFields,
            cardName,
            onCardName: this.handleCardName,
            cardNumber,
            onCardNumber: this.handleCardNumber,
            expiryMonth,
            onExpiryMonth: this.setter("expiryMonth"),
            expiryYear,
            onExpiryYear: this.setter("expiryYear"),
            cvv,
            onCvv: this.handleCvv,
          }}
        />

        <Message style={m.b2}>
          This transaction will show up as "SMO-TECH" on your statement.
        </Message>

        {Boolean(region) && (
          <Button
            {...{
              disabled: Boolean(purchase),
              loading,
              style: m.b3,
              onPress: this.handleSubmit,
            }}
          >
            Buy {region.name} package
          </Button>
        )}
      </Content.Form>
    );
  }
}
