import React from "react";
import { View } from "react-native";

import TextInput from "./text-input";

export default function Input({
  onChange,
  type = "text",
  value,
  disabled = false,
  style,
  ...rest
}) {
  if (type === "text") {
    const input = (
      <TextInput
        value={String(value)}
        onChangeText={onChange}
        editable={!disabled}
        style={[style, disabled && $.disabled]}
        {...rest}
      />
    );

    if (disabled) {
      return <View pointerEvents="none">{input}</View>;
    }

    return input;
  }

  // TODO
  return null;
}

const $ = {
  disabled: {
    backgroundColor: "#efefef",
    opacity: 0.75,
  },
};
