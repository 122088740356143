import { EMPTY, fromEvent, merge } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ofAction, onRegister } from "rnna/events";

export default onRegister(main);

function main({ api, resources: rest, router }) {
  const dispatch$ = fromEvent(router, "dispatch").pipe(
    mergeMap(({ componentId, query, params }) => {
      if (componentId === "Account") {
        return ofAction("app/fetchUser");
      }

      if (componentId === "Purchase") {
        return merge(rest.countries.list(), ofAction("app/fetchRegions"));
      }

      if (componentId === "Videos") {
        return ofAction("app/fetchVideos");
      }

      if (componentId === "Video") {
        return ofAction("app/fetchVideo", parseInt(params[0], 10));
      }

      return EMPTY;
    })
  );

  const httpReq$ = fromEvent(api, "req").pipe(
    map((req) => {
      const url = new URL(req.url);

      return {
        type: "http/req",
        payload: req,
        meta: { url },
      };
    })
  );

  const httpRes$ = fromEvent(api, "res").pipe(
    map((res) => {
      const url = new URL(res.request.url);

      return {
        type: "http/res",
        payload: res,
        meta: { url },
      };
    })
  );

  const httpError$ = fromEvent(api, "error").pipe(
    map((err) => {
      const url = new URL(err.request.url);

      return {
        type: "http/error",
        error: true,
        payload: err,
        meta: { url },
      };
    })
  );

  return merge(dispatch$, httpReq$, httpRes$, httpError$);
}
