import React from "react";
import { StyleSheet } from "react-native";

import { Button, Caption, Row } from "../lib";
import { colors, m, p, roundness } from "../theme";

export default function Message({
  action,
  children,
  error = false,
  onAction,
  style,
}) {
  return (
    <Row.LR style={[$.container, style, error && $.error]}>
      <Caption style={[$.text, error && $.errorText]}>{children}</Caption>
      {Boolean(action) && (
        <Button compact onPress={onAction} style={m.l2}>
          {action}
        </Button>
      )}
    </Row.LR>
  );
}

const $ = StyleSheet.create({
  container: {
    ...p.v2,
    ...p.h3,
    borderRadius: roundness,
    backgroundColor: colors.warning.surface,
  },
  text: {
    color: colors.warning.on,
  },
  error: {
    backgroundColor: colors.error.surface,
  },
  errorText: {
    color: colors.error.on,
  },
});
