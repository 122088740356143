import produce from "immer";

const initialState = {
  accessToken: null,
  refreshToken: null,
  userId: null,
  redirect: null,
  premium: false,
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case "app/setTokens":
    case "app/loginResult":
      draft.accessToken = payload.access_token || null;
      draft.refreshToken = payload.refresh_token || null;
      break;

    case "Videos/showVideo":
    case "Video/showLogin":
      if (!draft.accessToken) {
        draft.redirect = `videos/${payload}`;
      }
      break;

    case "Purchase/showLogin":
      draft.redirect = "purchase";
      break;

    case "app/redirect":
      draft.redirect = null;
      break;

    case "app/setUser":
      draft.userId = payload.result;
      break;

    default:
      break;
  }
}, initialState);
