import { Container } from "rnna";
import ResourceBundle from "@rnna/bundle-api-resource";
import DbBundle from "@rnna/bundle-db";
import RouterBundle from "@rnna/bundle-router";

import Env from "../env";
import routes from "../routes";
import createApi from "./api";
import screens from "./navigation";
import * as schemas from "./schemas";
import * as selectors from "./selectors";

const container = new Container();

// App
container.constant("schemas", schemas);

container.constant("api.url", Env.API_URL);
container.service("api", createApi, "api.*");

// DB bundle
container.constant("db.selectors", selectors);

// Router bundle
container.constant("router.screens", screens);
container.constant("router.routes", routes);
container.constant("router.options", {
  redirects: {
    account: ({ db }) => !db.isAuthenticated() && "login",
    login: ({ db }) => db.isAuthenticated() && "account",
  },
});

// Bundles
container.service("bundles.db", DbBundle);
container.service("bundles.router", RouterBundle);
container.factory("bundles.resources", ({ schemas: s }) => {
  return new ResourceBundle([
    s.city,
    s.country,
    s.model,
    s.plan,
    s.region,
    s.user,
    s.video,
  ]);
});

export default container;
