import React from "react";
import { StyleSheet } from "react-native";

import { Loader, Row } from "../lib";
import { m, p } from "../theme";

export default function AvatarLoader({ style }) {
  return (
    <Row.LR style={[$.container, style]}>
      <Row.L>
        <Loader.Round style={$.image} />
        <Loader.Text />
      </Row.L>
      <Loader.Text />
    </Row.LR>
  );
}

const $ = StyleSheet.create({
  container: {
    ...p[3],
    width: "100%",
  },
  image: {
    ...m.r2,
    width: 40,
    height: 40,
  },
});
