import { ActivityIndicator } from "react-native";

import { colors } from "../theme";

export default function Spinner({
  large = false,
  color: colorName = "accent",
  ...rest
}) {
  const sizeName = large ? "large" : "small";

  return (
    <ActivityIndicator
      size={sizeName}
      color={colors[colorName] || colorName}
      {...rest}
    />
  );
}
