import produce from "immer";

const initialState = {
  cityId: 1,
  cities: [],
  countries: [],
  genderId: 1,
  loading: false,
  page: 1,
  plans: [],
  videos: [],
  regionId: 2,
  regions: [],
  states: [],

  // http
  error: null,
  errors: [],
  errorFields: {},
};

let requests = [];

export default produce((draft, { type, payload, meta }) => {
  switch (type) {
    case "http/req":
      requests.push(meta.url.pathname);

      draft.loading = true;

      // New request: Reset
      draft.error = null;
      draft.errors = [];
      draft.errorFields = {};
      break;

    case "http/res":
    case "http/error": {
      if (requests.includes(meta.url.pathname)) {
        requests = requests.filter((path) => path !== meta.url.pathname);
      }

      if (!requests.length) {
        draft.loading = false;
      }

      if (type === "http/error") {
        const { status, response: data } = payload;

        draft.error = status;

        if (status === 422) {
          if (data.errorMessages) {
            draft.errors = data.errorMessages;
          } else if (data) {
            draft.errorFields = data;
          }
        } else if (status === 401) {
          draft.accessToken = null;
          draft.refreshToken = null;
        }
      }
      break;
    }

    case "Videos/paginate":
      draft.page = draft.page + 1;
      break;

    case "Purchase/showStates":
    case "Purchase/hideStates":
      draft.states = [];
      break;

    case "app/setStates":
      draft.states = payload.result;
      break;

    case "Purchase/getPlansByRegion":
      draft.regionId = payload;
      break;

    default:
      break;
  }
}, initialState);
