function account({ db }) {
  const user = db.getUser();

  return ["Account", { user }];
}

function login({ db }) {
  const error = db.getError();
  const errors = db.getErrors();
  const loading = db.isLoading();

  return ["Login", { error, errors, loading }];
}

function purchase({ db }) {
  const user = db.getUser();
  const cities = db.getCities();
  const countries = db.getCountries();
  const errorFields = db.getErrorFields();
  const loading = db.isLoading();
  const region = db.getRegion();
  const regions = db.getRegions();
  const states = db.getStates();

  const regionId = region ? region.id : 2;
  const plans = db.getPlans({ region: regionId });
  const purchase = db.findPurchaseByRegion(regionId);

  return [
    "Purchase",
    {
      cities: cities.filter((city) => city.region_id === regionId),
      countries,
      errorFields,
      loading,
      plans,
      purchase,
      region,
      regions,
      states,
      user,
    },
  ];
}

function videos({ db }) {
  const loading = db.isLoading();
  const authenticated = db.isAuthenticated();
  const premium = db.isPremium();
  const videos = db.getAllVideos();

  return ["Videos", { authenticated, loading, premium, videos }];
}

function video(videoId, { db }) {
  const id = parseInt(videoId, 10);

  const authenticated = db.isAuthenticated();
  const premium = db.isPremium();
  const loading = db.isLoading();
  const video = db.findVideo(id);
  const model = video ? db.findModel(video.model.id) : null;
  const teasing = db.isTeasing();

  return [
    "Video",
    { authenticated, id, loading, model, premium, teasing, video },
  ];
}

const routes = {
  account,
  login,
  purchase,
  "": videos,
  "videos/(\\d+)": video,
};

export default routes;
