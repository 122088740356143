import { StyleSheet } from "react-native";

import Caption from "./caption";

export default function Subheading({ style, children, ...rest }) {
  return (
    <Caption style={[$.text, style]} {...rest}>
      {children.join("").toUpperCase()}
    </Caption>
  );
}

const $ = StyleSheet.create({
  text: {
    fontWeight: "bold",
  },
});
