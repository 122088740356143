import React from "react";
import Col from "react-native-col";

import { colors } from "../theme";
import Spinner from "./spinner";

export default function Loader({ dark = false, ...rest }) {
  return (
    <Col.C.X {...rest}>
      <Spinner large color={dark ? colors.white : colors.muted} />
    </Col.C.X>
  );
}
