import React from "react";
import { StyleSheet } from "react-native";

import { Content, Loader } from "../lib";
import { m, p } from "../theme";

import AvatarLoader from "./avatar-loader";

export default function VideoItemLoader() {
  return (
    <Content style={$.container}>
      <Loader.Rect style={$.media} />
      <AvatarLoader />
    </Content>
  );
}

const $ = StyleSheet.create({
  container: {
    ...p.v3,
    alignItems: "center",
  },
  media: {
    ...m.r0,
    width: 266,
    height: 480,
  },
});
