import { StyleSheet } from "react-native";

import Caption from "./caption";
import { colors } from "../theme";

export default function Error({ style, ...rest }) {
  return <Caption style={[$.text, style]} {...rest} />;
}

const $ = StyleSheet.create({
  text: {
    color: colors.error.on,
  },
});
