import Http, { uses } from "@rnna/http";

const __DEV__ = process.env.NODE_ENV === "development";

export default function createApi({ url }) {
  const api = new Http({
    url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  // Log responses
  if (__DEV__) {
    api.use(uses.log);
  }

  // Map response data
  api.use(uses.response);

  // Catch & ignore AjaxError
  api.use(uses.error);

  return api;
}
