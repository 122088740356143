import { StyleSheet } from "react-native";

import Text from "./text";
import { colors } from "../theme";

export default function Caption({ style, ...rest }) {
  return <Text style={[$.text, style]} {...rest} />;
}

const $ = StyleSheet.create({
  text: {
    fontSize: 12,
    color: colors.textSecondary,
  },
});
