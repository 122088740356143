import { Dimensions } from "react-native";
import { createMargin, createPadding } from "react-native-spacing";

export const device = Dimensions.get("window");

// Other platforms:
// https://stackoverflow.com/a/47349998/925307
device.locale = navigator.language;

export const sizes = [0, 4, 8, 16, 32, 64];

export const m = createMargin(sizes);

export const p = createPadding(sizes);

export const roundness = 6;

// Colors
// https://www.materialpalette.com/indigo/red

const black = "#000000";
const white = "#FFFFFF";
const grey = "#757575";
const greyLight = "#aaa";
const greyLighter = "#dedede";

// const blue = "#1fc8db";
const yellow = "#ffea00";
// const green = "#00c853";

const pink = "#ff2484";
// const pinkLight = "#ff5983";
// const pinkDark = "#bb002f";

const primary = white;

export const colors = {
  black,
  white,
  yellow,
  primary,
  accent: pink,
  background: primary,
  surface: white,
  muted: greyLight,
  text: black,
  textSecondary: grey,
  divider: greyLighter,
  warning: {
    surface: "#fff8ea",
    on: "#573a08",
  },
  error: {
    surface: "#fff6f6",
    on: "#9f3a38",
    divider: "#e0b4b4",
  },
  on: {
    primary: black,
    accent: white,
    background: white,
    surface: black,
  },
};
