import { merge } from "rxjs";
import { mapAction, onBoot } from "rnna/events";

export default onBoot(main);

function main({ api, db, resources: rest }) {
  const token = db.getRefreshToken();

  const refresh$ = api
    .patch("token", { refresh_token: token })
    .pipe(mapAction("app/setTokens", (p) => p));

  return merge(refresh$, rest.cities.list());
}
