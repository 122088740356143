import React, { PureComponent } from "react";
import { Pressable, StyleSheet } from "react-native";

import Icon from "./icon";
import Text from "./text";
import { colors, m, p, roundness } from "../theme";

export default class Radio extends PureComponent {
  onPress = () => {
    const { value, onChange } = this.props;

    onChange(value);
  };

  render() {
    const { checked, label, style } = this.props;

    return (
      <Pressable
        onPress={this.onPress}
        style={[$.container, checked && $.active, style]}
      >
        {checked ? (
          <Icon
            name="radio_button_checked"
            color={colors.accent}
            style={m.r2}
          />
        ) : (
          <Icon
            name="radio_button_unchecked"
            color={colors.textSecondary}
            style={m.r2}
          />
        )}
        {typeof label === "string" ? <Text style={m.l2}>{label}</Text> : label}
      </Pressable>
    );
  }
}

const $ = StyleSheet.create({
  container: {
    ...p[3],
    ...m.b2,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderWidth: 2,
    borderColor: colors.divider,
    borderRadius: roundness,
  },
  active: {
    borderColor: colors.accent,
  },
});
