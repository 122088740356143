import React from "react";
import {
  ActivityIndicator,
  Platform,
  Pressable,
  StyleSheet,
  Text,
} from "react-native";

import { colors, m } from "../theme";

const isAndroid = Platform.OS === "android";

export default function Button({
  compact = false,
  children: title,
  disabled = false,
  loading = false,
  onPress,
  style,
}) {
  const $container = [
    $.container,
    compact && $.compact,
    disabled && $.disabled,
    style,
  ];

  const $text = [$.text, compact && $.textCompact, disabled && $.textDisabled];

  const content = loading ? (
    <ActivityIndicator color="white" style={m.l2} />
  ) : (
    <Text selectable={false} style={$text} numberOfLines={1}>
      {isAndroid || title}
    </Text>
  );

  return (
    <Pressable
      {...{
        onPress,
        disabled: disabled || loading,
        style: ({ pressed }) => [$container, pressed && $.pressed],
      }}
    >
      {content}
    </Pressable>
  );
}

const $ = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 18,
    paddingHorizontal: 24,
    borderRadius: 50,
    backgroundColor: colors.accent,
    elevation: isAndroid ? 3 : 0,
  },
  pressed: {
    backgroundColor: colors.primary,
  },
  compact: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  disabled: {
    opacity: 0.75,
  },
  text: {
    textAlign: "center",
    color: "white",
    fontSize: isAndroid ? 14 : 17,
    fontWeight: isAndroid ? "bold" : "600", // Semibold
  },
  textCompact: {
    fontSize: 13,
    fontWeight: "600", // Semibold
  },
  textDisabled: {
    // color: colors.primary,
  },
});
