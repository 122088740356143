import React from "react";
import { FlatList } from "react-native";

import { Message, Screen, VideoItem, VideoItemLoader } from "../components";
import { Action, Content } from "../lib";
import { p } from "../theme";

export default class Videos extends Screen {
  static displayName = "Videos";

  renderItem = ({ item }) => {
    return this.renderListItem(item);
  };

  handleEndReached = () => {
    const { premium } = this.props;

    if (premium) {
      this.dispatch("paginate");
    }
  };

  renderListItem({ id, model, image, ratio, width, height }) {
    return (
      <VideoItem
        {...{
          height,
          id,
          image,
          modelImage: model.image.url,
          modelName: model.name,
          modelReviews: model.reviews_count,
          modelScore: model.score,
          onPress: this.dispatcher("showVideo"),
          ratio,
          width,
        }}
      />
    );
  }

  renderContent() {
    const { authenticated, premium, videos = [] } = this.props;

    let header;

    if (!authenticated) {
      header = (
        <Message action="Log in" onAction={this.dispatcher("showLogin")}>
          Please sign in to watch more videos
        </Message>
      );
    } else if (!premium) {
      header = (
        <Message action="Purchase" onAction={this.dispatcher("showPurchase")}>
          You need a premium account to watch more videos
        </Message>
      );
    }

    const footer = !premium && (
      <Action
        accent
        title="Purchase"
        description="You need a premium account to see more videos"
        style={[p.h2, p.v4]}
        onPress={this.dispatcher("showPurchase")}
      />
    );

    const empty = (
      <>
        {[0, 1, 2].map((i) => (
          <VideoItemLoader key={i} />
        ))}
      </>
    );

    return (
      <Content.List>
        <FlatList
          data={videos}
          keyExtractor={({ id }) => `video-${id}`}
          ListEmptyComponent={empty}
          ListHeaderComponent={header}
          ListFooterComponent={footer}
          renderItem={this.renderItem}
          onEndReached={this.handleEndReached}
        />
      </Content.List>
    );
  }
}
