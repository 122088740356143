import React from "react";

import { Field, Input, Picker } from "../lib";
import { m } from "../theme";

export default function AddressField({
  errors,
  addressLine,
  onAddressLine,
  postCode,
  onPostCode,
  city,
  onCity,
  countries,
  countryId,
  onCountryId,
  states,
  stateId,
  onStateId,
}) {
  return (
    <Field title="Billing address">
      <Input
        value={addressLine}
        onChange={onAddressLine}
        placeholder="Address line"
        style={m.b2}
        error={Boolean(errors.address_line)}
      />

      <Input
        value={postCode}
        onChange={onPostCode}
        placeholder="Zip/Postal Code"
        style={m.b2}
        error={Boolean(errors.post_code)}
      />

      <Input
        value={city}
        onChange={onCity}
        placeholder="City"
        style={m.b2}
        error={Boolean(errors.city)}
      />

      <Picker
        value={countryId}
        onChange={onCountryId}
        style={m.b2}
        enabled={countries.length > 0}
        items={countries}
        placeholder="Choose country..."
        error={Boolean(errors.country_id)}
      />

      {states.length > 0 && (
        <Picker
          value={stateId}
          onChange={onStateId}
          style={m.b2}
          items={states}
          placeholder="Choose state..."
          error={Boolean(errors.state_id)}
        />
      )}
    </Field>
  );
}
