import React from "react";
import { StyleSheet } from "react-native";
import {
  TextRow,
  RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";

import { colors, m } from "../theme";
import Loader from "./loader.native";

export default Loader;

Loader.Rect = ({ style, ...rest }) => (
  <div className="show-loading-animation">
    <RectShape
      color={colors.divider}
      {...rest}
      style={StyleSheet.flatten([$.rect, style])}
    />
  </div>
);

Loader.Round = ({ style, ...rest }) => (
  <div className="show-loading-animation">
    <RoundShape
      color={colors.divider}
      {...rest}
      style={StyleSheet.flatten([style])}
    />
  </div>
);

Loader.Text = ({ style, ...rest }) => (
  <div className="show-loading-animation">
    <TextRow
      color={colors.divider}
      {...rest}
      style={StyleSheet.flatten([$.text, style])}
    />
  </div>
);

const $ = StyleSheet.create({
  rect: {
    ...m.r0,
    borderRadius: 2,
  },
  text: {
    ...m.t0,
    height: 10,
    borderRadius: 4,
    width: 100,
  },
});
