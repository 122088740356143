import React from "react";
import { StyleSheet } from "react-native";

import { Button, Col, Text } from "../lib";
import { colors, m } from "../theme";

export default function Action({
  accent = false,
  dark = false,
  description,
  onPress,
  title,
  style,
}) {
  return (
    <Col.C.X style={[accent && $.accent, style]}>
      <Text style={[m.b3, dark && $.onDark]}>{description}</Text>
      <Button {...{ onPress }}>{title}</Button>
    </Col.C.X>
  );
}

const $ = StyleSheet.create({
  onDark: {
    color: colors.white,
  },
  accent: {
    backgroundColor: colors.warning.surface,
  },
});
